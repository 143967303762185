import type {
  AddPaymentInfoDetails,
  AddShippingInfoDetails,
  BeginCheckoutDetails,
  MasterProduct,
  Product,
  PurchaseDetails,
  ViewItemListDetails,
} from "~/types/common";
import {
  useNetPriceCalculator,
  usePriceCalculator,
} from "~/composables/usePriceCalculator";
import { useCartStore } from "~/stores/cartStore";
import { useMeilisearch } from "~/utils/meilisearch";

const priceCalculator = useNetPriceCalculator();

// Private functions
const findProductIndexInCart = (
  product: MasterProduct,
  variant: Product,
): number => {
  const cart = useCartStore();

  if (
    cart.cartData &&
    cart.cartData.products &&
    Array.isArray(cart.cartData.products)
  ) {
    const callbackFn = (item) =>
      item.masterProduct.id === product.id &&
      item.product.id === variant.product.id;
    const index = cart.cartData.products.findIndex(callbackFn);

    if (index === -1) {
      // Not in the cart yet, this becomes the last one
      return cart.cartData.products.length - 1;
    } else {
      return index;
    }
  }

  return -1;
};

const getMasterProductsByCart = () => {
  const config = useRuntimeConfig();
  const cart = useCartStore();

  if (
    cart.cartData &&
    cart.cartData.products &&
    Array.isArray(cart.cartData.products)
  ) {
    const skus = cart.cartData.products.map((item) => item.masterProduct.sku);

    return useMeilisearch(config.public.meilisearchIndexMasterproducts, {
      filter: [`sku IN [${skus.join(",")}]`],
    });
  } else {
    return null;
  }
};

const getCategoriesFromBreadcrumb = (breadcrumb: string): string[] => {
  // TODO: generate another property with only (sub)category name?
  return breadcrumb.split(" > ");
};

const getSubcategoryFromBreadcrumb = (breadcrumb: string): string => {
  const parts = getCategoriesFromBreadcrumb(breadcrumb);
  const subcategory = parts.pop();

  return subcategory + "";
};

const getMainCategory = (product: MasterProduct): string => {
  const key = "masterproduct_termekkategoria_property.lvl0";

  if (
    key in product &&
    Array.isArray(product[key]) &&
    product[key].length > 0
  ) {
    return product[key][0];
  }

  return "";
};

const getSubcategory = (product: MasterProduct, key: string) => {
  if (
    key in product &&
    Array.isArray(product[key]) &&
    product[key].length > 0
  ) {
    return getSubcategoryFromBreadcrumb(product[key][0]);
  }

  return "";
};

const getFirstSubcategory = (product: MasterProduct): string => {
  return getSubcategory(product, "masterproduct_termekkategoria_property.lvl1");
};

const getSecondSubcategory = (product: MasterProduct): string => {
  return getSubcategory(product, "masterproduct_termekkategoria_property.lvl2");
};

const getItem = (
  product: MasterProduct,
  variant?: Product,
  quantity: number = 1,
) => {
  const mainCategory = getMainCategory(product);
  const firstSubcategory = getFirstSubcategory(product);
  const secondSubcategory = getSecondSubcategory(product);
  const item = {
    item_id: product.sku,
    item_name: product.name,
    item_brand: product.masterproduct_gyarto_property,
    quantity,
  };

  if (variant) {
    const indexInCart = findProductIndexInCart(product, variant);

    item.price = priceCalculator.getVariantPrice(variant);
    item.discount = priceCalculator.getVariantDiscount(variant);
    item.item_variant = `${variant.product.product_meret_property}, ${variant.product.product_szin_property}`; // TODO: looks good?

    if (indexInCart !== -1) {
      item.index = indexInCart;
    }
  } else {
    item.price = priceCalculator.getProductMinimumPrice(product);
  }

  if (mainCategory.length > 0) {
    item.item_category = mainCategory;
  }

  if (firstSubcategory.length > 0) {
    item.item_category2 = firstSubcategory;
  }

  if (secondSubcategory.length > 0) {
    item.item_category3 = secondSubcategory;
  }

  return item;
};

/**
 * Fetches the master products from meili search based on the cart data and
 * prepares the non-event specific key-value pairs (currency, value, items,
 * etc.) and calls the sendEventCallback function passing the prepared
 * ecommerce object.
 *
 * @param sendEventCallback
 */
const prepareEventData = (
  sendEventCallback: (ecommerce: Record<string, any>) => void,
) => {
  const masterProductsPromise = getMasterProductsByCart();

  if (!(masterProductsPromise instanceof Promise)) {
    return;
  }

  masterProductsPromise
    .then((masterProducts: MasterProduct[]) => {
      const config = useRuntimeConfig();
      const cart = useCartStore();
      const items = [];
      const priceCalculator = usePriceCalculator();
      const itemsTotal = cart.cartData.info.items_total;
      const value = priceCalculator.getNetValue(itemsTotal);

      for (const product of masterProducts) {
        const cartItem = cart.cartData.products.find(
          (item) => item.masterProduct.id === product.id,
        );

        if (!cartItem) {
          continue;
        }

        const variantId = cartItem.product.id;
        const variant = product.products.find(
          (item) => item.product.id === variantId,
        );

        if (!variant) {
          continue;
        }

        items.push(getItem(product, variant));
      }

      if (items.length === 0) {
        return;
      }

      sendEventCallback({
        currency: config.public.currency,
        value,
        items,
      });
    })
    .catch(() => {
      // TODO: handle error
    });
};

const extendData = (data: Record<string, any>): Record<string, any> => {
  const { status: authStatus, data: authData } = useAuth();

  if (
    "ecommerce" in data &&
    authStatus.value === "authenticated" &&
    "user" in authData.value
  ) {
    if ("id" in authData.value.user) {
      data.ecommerce.userID = authData.value.user.id;
    }

    if ("email" in authData.value.user) {
      data.ecommerce.email = authData.value.user.email;
    }
  }

  return data;
};

const sendEvent = (data: Record<string, any>) => {
  if (typeof dataLayer === "object" && Array.isArray(dataLayer)) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(extendData(data));
  }
};

// Public functions
export function viewItemList(
  products: MasterProduct[],
  eventDetails: ViewItemListDetails = {},
) {
  const ecommerce = {
    items: [],
  };

  if (eventDetails.listId) {
    ecommerce.item_list_id = eventDetails.listId;
  }

  if (eventDetails.listName) {
    ecommerce.item_list_name = eventDetails.listName;
  }

  if (!Array.isArray(products)) {
    return;
  }

  for (const product of products) {
    ecommerce.items.push(getItem(product));
  }

  sendEvent({
    event: "view_item_list",
    ecommerce,
  });
}

export function viewItem(product: MasterProduct, variant?: Product) {
  const config = useRuntimeConfig();
  const price = variant
    ? priceCalculator.getVariantPrice(variant)
    : priceCalculator.getProductMinimumPrice(product);
  const item = getItem(product, variant, 1);

  sendEvent({
    event: "view_item",
    ecommerce: {
      currency: config.public.currency,
      value: price,
      items: [item],
    },
  });
}

export function addToCart(
  product: MasterProduct,
  variant: Product,
  quantity: number,
) {
  const config = useRuntimeConfig();
  const price = priceCalculator.getVariantPrice(variant);
  const item = getItem(product, variant, quantity);

  sendEvent({
    event: "add_to_cart",
    ecommerce: {
      currency: config.public.currency,
      value: price * quantity,
      items: [item],
    },
  });
}

export function removeFromCart(
  product: MasterProduct,
  variant: Product,
  quantity: number,
) {
  const config = useRuntimeConfig();
  const price = priceCalculator.getVariantPrice(variant);
  const item = getItem(product, variant, quantity);

  sendEvent({
    event: "remove_from_cart",
    ecommerce: {
      currency: config.public.currency,
      value: price * quantity,
      items: [item],
    },
  });
}

export function viewCart() {
  prepareEventData((ecommerce: Record<string, any>) => {
    sendEvent({
      event: "view_cart",
      ecommerce,
    });
  });
}

export function beginCheckout(eventDetails: BeginCheckoutDetails) {
  prepareEventData((ecommerce: Record<string, any>) => {
    if (eventDetails.coupon) {
      ecommerce.coupon = eventDetails.coupon;
    }

    sendEvent({
      event: "begin_checkout",
      ecommerce,
    });
  });
}

export function addShippingInfo(eventDetails: AddShippingInfoDetails) {
  prepareEventData((ecommerce: Record<string, any>) => {
    if (eventDetails.coupon) {
      ecommerce.coupon = eventDetails.coupon;
    }

    if (eventDetails.shippingTier) {
      ecommerce.shipping_tier = eventDetails.shippingTier;
    }

    sendEvent({
      event: "add_shipping_info",
      ecommerce,
    });
  });
}

export function addPaymentInfo(eventDetails: AddPaymentInfoDetails) {
  prepareEventData((ecommerce: Record<string, any>) => {
    if (eventDetails.coupon) {
      ecommerce.coupon = eventDetails.coupon;
    }

    if (eventDetails.paymentType) {
      ecommerce.payment_type = eventDetails.paymentType;
    }

    sendEvent({
      event: "add_payment_info",
      ecommerce,
    });
  });
}

export function purchase(eventDetails: PurchaseDetails) {
  prepareEventData((ecommerce: Record<string, any>) => {
    if (eventDetails.coupon) {
      ecommerce.coupon = eventDetails.coupon;
    }

    if (eventDetails.shipping) {
      ecommerce.shipping = eventDetails.shipping;
    }

    if (eventDetails.tax) {
      ecommerce.tax = eventDetails.tax;
    }

    if (eventDetails.phone) {
      ecommerce.phone = eventDetails.phone;
    }

    if (eventDetails.firstName) {
      ecommerce.first_name = eventDetails.firstName;
    }

    if (eventDetails.lastName) {
      ecommerce.last_name = eventDetails.lastName;
    }

    if (eventDetails.postcode) {
      ecommerce.postcode = eventDetails.postcode;
    }

    if (eventDetails.city) {
      ecommerce.city = eventDetails.city;
    }

    ecommerce.email = eventDetails.email;
    ecommerce.transaction_id = eventDetails.transactionId;

    sendEvent({
      event: "purchase",
      ecommerce,
    });
  });
}
